var main = (function($) {
  function init() {
  }

   	jQuery('body#lang-fi').find('.responsive-menu-search-box').attr("placeholder", "Hae...");

  	// Toggle search
  	// --------------------------------------------------------
	jQuery(".navigation-search").click(function () {
		jQuery('.header-search-form-container').slideToggle("slow");
	  	jQuery(this).toggleClass('search-closed search-opened')
	});


	// Toggle more abour services related to ski lopes map
	// --------------------------------------------------------
	jQuery(".toggle-service").click(function (e) {
		e.preventDefault();
		jQuery(this).closest('.service').find('.more-about-service').toggle();
	});



  	// Sticky footer
	// --------------------------------------------------------
	docHeight = jQuery(window).height();
	footerHeight = jQuery('#colophon').height();
	footerTop = jQuery('#colophon').position().top + footerHeight;

	if (footerTop < docHeight) {
		jQuery('#colophon').css('margin-top', 0+ (docHeight - footerTop) + 'px');
	}


	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = decodeURIComponent(window.location.search.substring(1)),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
	};


	// Replace all SVG images with inline SVG
	// --------------------------------------------------------
	function svgToInlineSVG() {
		jQuery('img.svg').each(function(){
		    var $img = jQuery(this);
		    var imgID = $img.attr('id');
		    var imgClass = $img.attr('class');
		    var imgURL = $img.attr('src');

		    jQuery.get(imgURL, function(data) {
		        // Get the SVG tag, ignore the rest
		        var $svg = jQuery(data).find('svg');

		        // Add replaced image's ID to the new SVG
		        if(typeof imgID !== 'undefined') {
		            $svg = $svg.attr('id', imgID);
		        }
		        // Add replaced image's classes to the new SVG
		        if(typeof imgClass !== 'undefined') {
		            $svg = $svg.attr('class', imgClass+' replaced-svg');
		        }

		        // Remove any invalid XML tags as per http://validator.w3.org
		        $svg = $svg.removeAttr('xmlns:a');

		        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
		        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
		            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
		        }

		        // Replace image with new SVG
		        $img.replaceWith($svg);

		    }, 'xml');

		});
	}

	svgToInlineSVG();


	// -------------------------------------------------------
	// FRONTPAGE CONTENT LIFTS
	// -------------------------------------------------------
	jQuery('.small-lift').on('click', function() {
		external = jQuery(this).attr('data-external');
		pagelink = jQuery(this).attr('data-url');
		if(external == 'yes')
		{
			window.open(pagelink);
		}
		else
		{
			window.location = pagelink;
		}

	});
	jQuery('.liftslider-slider-container').on('click', function() {
		pagelink = jQuery(this).attr('data-url');
		window.location = pagelink;
	});


	// -------------------------------------------------------
	// ASK OFFER / QUOTATION FORM CODES
	// -------------------------------------------------------

	// Functions
	// -------------------------------------------------------
	
	// Get selected snacks and services
	function validateProducts(id) {

		// checks only inputs with type "text" inside div id-calc
		var div = jQuery("#" + id + "-calc");

		jQuery(div).find(".single-product").each(function() {

			productAmount = jQuery(this).find('input[type="text"]').val();

			if(productAmount > 0)
			{		        	
				productTitle = jQuery(this).find('.product-title').text();

				if(id == 'helsinkiservices')
				{
					selectedHelsinkiServices += productAmount + ' kpl ' + productTitle + '<br />';
				}

				if(id == 'snacks')
				{
					selectedSnacks += productAmount + ' kpl ' + productTitle + '<br />';
				}
				/*
				if(id == 'otherservices')
				{
					selectedOtherServices += productAmount + ' kpl ' + productTitle + '<br />';
				}
				*/
			}
		});

	}




	// Continue to quoation form from ask-offer lift
	// -------------------------------------------------------
	jQuery('.to-quotation').on('click', function() {

		from = jQuery('.from').val();

		routeType = jQuery( ".route-type option:selected" ).val();

		passengers = jQuery('.passengers-amount').val();

		selectedDate = jQuery('.selectedDate').val();

		window.location = '/tarjouspyynto?routetype=' + routeType + '&date=' + selectedDate + '&from=' + from + '&passengers=' + passengers;

	});


	// Set fields on quotation form which has already been set on ask-offer lift
	// -------------------------------------------------------
	var routetypeSettled =  getUrlParameter('routetype');
	var dateSettled =  getUrlParameter('date');
	var fromSettled =  getUrlParameter('from');
	var passengersSettled = getUrlParameter('passengers');


	if( routetypeSettled != "" && typeof routetypeSettled != 'undefined')
	{
		jQuery('a.routetype-button').removeClass('selected');
		jQuery(".routetype-button[data-value='" + routetypeSettled + "']").addClass('selected');

		if(routetypeSettled == '2')
		{
			jQuery('.return-route').hide();
		}
	}

	if( dateSettled != "" && typeof dateSettled != 'undefined')
	{
		jQuery(".ask-offer-lift-datepicker").val(dateSettled);
	}

	if( fromSettled != "" && typeof fromSettled != 'undefined')
	{
		jQuery(".from-field").val(fromSettled);
	}

	if( passengersSettled != "" && typeof passengersSettled != 'undefined')
	{
		jQuery('#passengersRate').val(passengersSettled);
	}

	/*
	jQuery("#passengersRate").keyup(function () {
		selectedPassengersRateForFilter = jQuery(this).val();
  
	    jQuery("[data-persons]").filter(function() {
	        return parseInt($(this).attr('data-persons'), 10) < selectedPassengersRateForFilter;
	    }).hide();

	    jQuery("[data-persons]").filter(function() {
	        return parseInt($(this).attr('data-persons'), 10) > selectedPassengersRateForFilter;
	    }).show();
    });
	*/



	// -------------------------------------------------------
	// REQUEST OFFER / QUOTATION FORM FUNCTIONALS
	// -------------------------------------------------------
	jQuery('.accessorySelectTitle').on('click', function() {
		jQuery('.accessoryList').toggle();
	});

	jQuery( "div.accessoryList" )
	  .mouseenter(function() {
	    // Do nothing
	  })
	  .mouseleave(function() {
		jQuery('.accessoryList').hide();
	  });


	jQuery('.single-bus').on('click', function() {

		if( jQuery('#busSelectNoSelection-checkbox').hasClass('checked') )
		{
			jQuery('#busSelectNoSelection-checkbox').removeClass('checked');
			jQuery('#busSelectNoSelection-checkbox').addClass('unchecked');
		}

        jQuery(this).toggleClass("selected-bus");  // remove active class from all
	});

	// Chooce trip type
	jQuery('.select1').on('click', function() {
		jQuery('a.routetype-button').removeClass('selected');
		jQuery(this).addClass('selected');
		jQuery('.route').hide();
		jQuery('.return-trip').show();
		jQuery('.add-row').hide();
		jQuery('.delete-row-btn').hide();
		jQuery('.first-route').show();		
		jQuery('.return-route').show();	
		jQuery('.return-trip-title').show();
	});

	jQuery('.select2').on('click', function() {
		jQuery('a.routetype-button').removeClass('selected');
		jQuery(this).addClass('selected');
		jQuery('.route').hide();
		jQuery('.return-trip').show();
		jQuery('.add-row').hide();
		jQuery('.first-route').show();		
		jQuery('.return-route').hide();		
		jQuery('.delete-row-btn').hide();
		jQuery('.return-trip-title').hide();
	});

	jQuery('.select3').on('click', function() {
		jQuery('a.routetype-button').removeClass('selected');
		jQuery(this).addClass('selected');
		jQuery('.return-trip').show();
		jQuery('.add-row').show();
		jQuery('.delete-row-btn').css('display', 'inline-block');
		jQuery('.return-route').show();	
		jQuery('.return-trip-title').hide();
	});

	// Chooce "Suggest bus for me"
	// -----------------------------------------
	jQuery('#busSelectNoSelection').on('click', function() {
		jQuery(this).find('.fa').toggleClass("unchecked checked");

		if( jQuery(this).find('.fa').hasClass('checked'))
		{
        	jQuery(".single-bus").removeClass("selected-bus");  
		}
	});

    jQuery('body').on('click', '.delete-row-btn', function (){
		jQuery(this).closest('.route').remove();
	});


	jQuery('.add-row-btn').on('click', function() {
		newRoute = jQuery('.first-route').clone().insertAfter(".route:last");
		newRoute.removeClass('first-route');
		newRoute.find('input').val('');
		jQuery( ".ask-offer-lift-datepicker" ).datepicker();
	});

	jQuery('.toggle-helsinki-services').on('click', function() {
		jQuery('.list-of-helsinki-services').toggle();
		jQuery(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
	});

	jQuery('.toggle-snacks').on('click', function() {
		jQuery('.list-of-snacks').toggle();
		jQuery(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
	});

	jQuery('.toggle-other-services').on('click', function() {
		jQuery('.list-of-other-services').toggle();
		jQuery(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
	});


	// Edit steps on quotation form 
	// -----------------------------------------
	jQuery('.edit-step1').on('click', function() {
		jQuery('.step1').show();
		jQuery('.step1-summary').hide();
		jQuery('.step2').hide();
		jQuery('.step2-summary').show();
		jQuery('.step3').hide();
		jQuery('.step3-summary').show();
		jQuery('.step4').hide();
		jQuery('.step4-summary').show();
	});

	jQuery('.edit-step2').on('click', function() {
		jQuery('.step1').hide();
		jQuery('.step1-summary').show();
		jQuery('.step2').show();
		jQuery('.step2-summary').hide();
		jQuery('.step3').hide();
		jQuery('.step3-summary').show();
		jQuery('.step4').hide();
		jQuery('.step4-summary').show();
	});

	jQuery('.edit-step3').on('click', function() {
		jQuery('.step1').hide();
		jQuery('.step1-summary').show();
		jQuery('.step2').hide();
		jQuery('.step2-summary').show();
		jQuery('.step3').show();
		jQuery('.step3-summary').hide();
		jQuery('.step4').hide();
		jQuery('.step4-summary').show();
	});

	jQuery('.edit-step4').on('click', function() {
		jQuery('.step1').hide();
		jQuery('.step1-summary').show();
		jQuery('.step2').hide();
		jQuery('.step2-summary').show();
		jQuery('.step3').hide();
		jQuery('.step3-summary').show();
		jQuery('.step4').show();
		jQuery('.step4-summary').hide();
	});



	// Next steps on quotation form
	// -----------------------------------------

	// For analytics
    jQuery(".to-step2-event").one('click', function (){
		ga('send', 'event', 'Tarjouspyynto', 'Klikkaus', 'Eteneminen vaiheeseen 2');       
    });


	// Go to step 2
	jQuery('.to-step2').on('click', function() {

		amountOfPassengers = jQuery('#passengersRate').val();

		if(amountOfPassengers < 1)
		{
			jQuery(this).closest('.continue').find('.error-message-1').show();
			return false;
		}
		else 
		{
			jQuery(this).closest('.continue').find('.error-message-1').hide();
		}

		selectedBusses = '';

		amountOfSelectedBusses = 0;

		jQuery('#busses-container-isotope').find(".single-bus").each(function() {

			if( jQuery(this).hasClass('selected-bus') )
			{
				amountOfSelectedBusses ++;
				selectedBusName = jQuery(this).find('.busName').text();
				selectedBusses += selectedBusName + '\n';
			}

		});

		if(amountOfSelectedBusses == 0 && jQuery('#busSelectNoSelection-checkbox').hasClass('unchecked') )
		{
			jQuery(this).closest('.continue').find('.error-message-2').show();
			return false;
		}
		else 
		{
			jQuery(this).closest('.continue').find('.error-message-2').hide();
		}

		if(amountOfSelectedBusses == 0 && jQuery('#busSelectNoSelection-checkbox').hasClass('checked') )
		{
			selectedBusses = 'Ehdotamme sopivaa autoa.';
		}

		jQuery('.step1').hide();
		jQuery('.step1-summary').show();
		jQuery('.step1-summary').find('.step-summary-content').show();
		jQuery('.step1-summary').find('.edit').show();
		jQuery('.step1-summary').find('.step-summary-content').find('.passengers').find('span').html(amountOfPassengers);
		jQuery('.step1-summary').find('.step-summary-content').find('.busses').find('span').html(selectedBusses);
		jQuery('.step2-summary').hide();
		jQuery('.step2').show();
		jQuery('html, body').animate({
		    scrollTop: (jQuery('.step2').offset().top - 150)
		},500);


	});

	// For analytics
    jQuery(".to-step3-event").one('click', function (){
       ga('send', 'event', 'Tarjouspyynto', 'Klikkaus', 'Eteneminen vaiheeseen 3');
    });

	// Go to step 3
	jQuery('.to-step3').on('click', function() {

		// Get selected route
		selectedRoute = jQuery('.select-route').find('.selected').text();
		selectedRouteType = jQuery('.select-route').find('.selected').attr('data-value');

		routeInformation = '';
		routeInformationHtml = '';

		routecalculator = 0;
		jQuery('.routes').find(".route").each(function() {
			routecalculator++;
			if(routecalculator == 1)
			{
				routeInformation += 'Reittityyppi: ' + selectedRoute + '\n\n'; 
			}

			placeFrom = jQuery(this).find('input.from-field').val();
			placeWhere = jQuery(this).find('input.to-field').val();
			departureDate = jQuery(this).find('input.hasDatepicker').val();
			departureTime = jQuery(this).find('input.ask-offer-lift-time').val();
			routeInformationHtml += placeFrom + ' - ' + placeWhere + ' ' + departureDate + ' ' + departureTime + '<br />'; 
			routeInformation += 'Mistä: ' + placeFrom + '\n' + 'Minne: ' + placeWhere + '\n' + 'Päivämäärä: ' + departureDate + '\n' + 'Kellonaika: ' + departureTime + '\n\n'; 
		});

		// Additional information about the route
		additionaRouteInformation = jQuery('.moreAboutTheRoute').val();

		jQuery('.step2').hide();
		jQuery('.step2-summary').show();
		jQuery('.step2-summary').find('.step-summary-content').show();
		jQuery('.step2-summary').find('.edit').show();
		jQuery('.step2-summary').find('.step-summary-content').find('.title').html(selectedRoute);
		jQuery('.step2-summary').find('.step-summary-content').find('.selected-routes').html(routeInformationHtml);
		jQuery('.step3-summary').hide();
		jQuery('.step3').show();
		jQuery('html, body').animate({
		    scrollTop: (jQuery('.step3').offset().top - 150)
		},500);


	});

    jQuery(".to-step4-event").one('click', function (){
       ga('send', 'event', 'Tarjouspyynto', 'Klikkaus', 'Eteneminen vaiheeseen 4');
    });

	// Go to step 4
	jQuery('.to-step4').on('click', function() {

		selectedHelsinkiServices = '';
		selectedSnacks = '';
		selectedOtherServices = '';

		// check selected Helsinki services
		// --------------------------------
		id = "helsinkiservices";
		validateProducts(id);

		// check selected snacks
		// --------------------------------
		id = "snacks";
		validateProducts(id);

		// check selected other services
		// --------------------------------
		// id = "otherservices";
		// validateProducts(id);

		var selected = [];
		jQuery('#otherservices-calc input:checked').each(function() {
			otherServiceName = jQuery(this).attr('name');
		    selected.push(jQuery(this).attr('name'));
			selectedOtherServices += otherServiceName + '<br />';

		});


		// Additional information related on products / services
		// --------------------------------
		additionalServicesInformation = jQuery('.products-additional-information').val();

		jQuery('.step3').hide();
		jQuery('.step3-summary').show();
		jQuery('.step3-summary').find('.step-summary-content').show();
		jQuery('.step3-summary').find('.edit').show();

		if(selectedHelsinkiServices != '')
		{
			jQuery('.helsinkiservices-summary').show();
			jQuery('.helsinkiservices-summary').find('.selected-helsinkiservices').html(selectedHelsinkiServices);
		}
		else {
			jQuery('.helsinkiservices-summary').hide();
		}

		if(selectedOtherServices != '')
		{
			jQuery('.additionalservices-summary').show();
			jQuery('.additionalservices-summary').find('.selected-additionalservices').html(selectedOtherServices);
		}
		else {
			jQuery('.additionalservices-summary').hide();
		}

		if(selectedSnacks != '')
		{
			jQuery('.snacks-summary').show();
			jQuery('.snacks-summary').find('.selected-snacks').html(selectedSnacks);
		}
		else {
			jQuery('.snacks-summary ').hide();
		}

		jQuery('.step4-summary').find('.edit').show();
		jQuery('.step4-summary').hide();
		jQuery('.step4').show();
		jQuery('html, body').animate({
		    scrollTop: (jQuery('.step4').offset().top - 150)
		},500);


	});

	// -----------------------------------------
	// SEND ASK OFFER FORM TO E-MAIL
	// -----------------------------------------
	jQuery('.send-ask-offer').on('click', function() {

		ga('send', 'event', 'Tarjouspyynto', 'Klikkaus', 'Laheta tarjouspyynto');

		// GET CONTACT DETAILS
		// --------------------------------
		contactperson = jQuery('.ask-offer-contactdetails').find('.contactperson').val();
		company = jQuery('.ask-offer-contactdetails').find('.company').val();
		phonenumber = jQuery('.ask-offer-contactdetails').find('.contact-phonenumber').val();
		email = jQuery('.ask-offer-contactdetails').find('.contact-email').val();
		orderInfo = jQuery('.ask-offer-contactdetails').find('.order-info').val();

		if(contactperson == '' || phonenumber == '' || email == '')
		{
			jQuery(this).closest('.continue').find('.error-message-1').show();
			return false;
		}

		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
 		email_validation = regex.test(email);
		if(email_validation == false){
			jQuery(this).closest('.continue').find('.error-message-1').hide();
			jQuery(this).closest('.continue').find('.error-message-2').show();
			return false;
		}


		//validate phone
		var phonenumber = jQuery('.ask-offer-contactdetails').find('.contact-phonenumber').val(),
		    intRegex = /[0-9 -()+]+$/;
		if((phonenumber.length < 6) || (!intRegex.test(phonenumber)))
		{
			jQuery(this).closest('.continue').find('.error-message-1').hide();
			jQuery(this).closest('.continue').find('.error-message-2').hide();	
			jQuery(this).closest('.continue').find('.error-message-3').show();		     
		    return false;
		}

	
		jQuery('.steps').hide();
		jQuery('.thankyou-content').show();

		dataString = 'amountOfPassengers=' + amountOfPassengers + '&selectedBusses=' + selectedBusses + '&routeInformationHtml=' + routeInformationHtml 
		+ '&additionaRouteInformation=' + additionaRouteInformation + '&selectedHelsinkiServices=' + selectedHelsinkiServices
		+ '&selectedSnacks=' + selectedSnacks + '&selectedOtherServices=' + selectedOtherServices + '&additionalServicesInformation=' + additionalServicesInformation
		+ '&contactperson=' + contactperson + '&company=' + company + '&phonenumber=' + phonenumber + '&email=' + email + '&orderInfo=' + orderInfo;

		newsletter_order = jQuery( 'input[name="newsletter-order"]:checked' ).val();
		
		if(newsletter_order == '1')
		{
			jQuery.get("http://ambassador.redland.fi/t/r/s/krktjtl/?cm-krktjtl-krktjtl="+email).done(function () {
			});
		}

		jQuery.ajax({
			url: ajaxurl,    
			type: "POST",
			cache: false,
			data: dataString + '&action=sendmail' //action defines which function to use in add_action
		});
		

	});





  return {
    init: init
  };
}(jQuery));
